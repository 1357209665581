
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import { PaginationParam } from '@/views/type'
import Pagination from '@/components/Pagination/index.vue'
import numsTips from '@/components/numsTips/index.vue'
import { autoAssessmentProgressList, deleteWork } from '@/api/creditRatingManagement'
@Component({
  components: {
    Pagination,
    numsTips
  }
})
export default class CreditRatingProgress extends Vue {
  private loadingTxt: string = loadingText
  private nowDownloadUrl: string = ''
  private nowFileUrl: string = ''
  private nowFileName: string = ''
  private statusOptions: any = [
    { label: '等待中', value: 0 },
    { label: '进行中', value: 1 },
    { label: '已完成', value: 2 }
  ]
  private createTime: any = '' // 创建时间
  private finalTime: any = '' // 完成时间
  private formInfo: any = {
    status: ''
  }
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private loading = false
  created() {
    this.getList()
  }
  private getList() {
    this.loading = true
    if (this.createTime) {
      this.$set(this.formInfo, 'createdTimeStart', this.createTime[0])
      this.$set(this.formInfo, 'createdTimeEnd', this.createTime[1])
    } else {
      this.$set(this.formInfo, 'createdTimeStart', '')
      this.$set(this.formInfo, 'createdTimeEnd', '')
    }
    if (this.finalTime) {
      this.$set(this.formInfo, 'finishTimeStart', this.finalTime[0])
      this.$set(this.formInfo, 'finishTimeEnd', this.finalTime[1])
    } else {
      this.$set(this.formInfo, 'finishTimeStart', '')
      this.$set(this.formInfo, 'finishTimeEnd', '')
    }
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      !params[key] && params[key] !== 0 && delete params[key]
    })
    autoAssessmentProgressList(params).then((res) => {
      if (res.data.data) {
        this.tableData = res.data.data
        this.paginationParam.total = res.data.total
        this.loading = false
      }
    })
  }
  // 搜索
  private handleSearch() {
    this.getList()
  }
  // 删除
  private handleDel(id: any) {
    this.$confirm('是否确认删除该任务？', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.loading = true
        deleteWork({ id: id })
          .then((res) => {
            this.$message.success('删除成功')
            this.getList()
          })
          .catch(() => {
            this.loading = false
          })
      })
      .catch(() => {})
  }
}
